<template>
  <div id="app1" class="app-container">
    <div class="content">
      <div class="detail">
        <div class="box">
          <span class="label">客户名称：</span>
          <div class="text">{{ form.customerName }}</div>
        </div>
        <div class="box">
          <span class="label">门店名称：</span>
          <div class="text">{{ form.shopName }}</div>
        </div>
        <div class="box">
          <span class="label">端口号：</span>
          <div class="text">{{ form.port }}</div>
        </div>
        <div class="box">
          <span class="label">盒子Mac-ID：</span>
          <div class="text">{{ form.MAC }}</div>
        </div>
        
        <div class="box">
          <span class="label">盒子状态：</span>
          <div class="text" v-if="form">{{ form.status ? '在线' : '离线' }}</div>
        </div>
      </div>
      <div class="detail">
        <div class="box">
          <span class="label">摄像头SN：</span>
          <div class="text">{{ form.cameraSN }}</div>
        </div>
        <div class="box">
          <span class="label">摄像头型号：</span>
          <div class="text">{{ form.cameraModel }}</div>
        </div>
        <div class="box">
          <span class="label">产品ID：</span>
          <div class="text" v-if="form">{{ productId }}</div>
        </div>
      </div>
    </div>
    <div class="contentUrl">
      <div class="detail">
        <div class="box">
          <div class="title">录屏视频截图</div>
          <img :src="form.screenCaptureUrl" alt="" v-if="screenImgUrlIsGood === 2" />
          <div v-if="screenImgUrlIsGood === 1" class="noImg">
            <div>当前无截图</div>
          </div>
          <div>
            <el-button @click="linklive(0)" type="text" size="small" class="url" v-if="form.screenLiveUrl"
              >查看录屏视频</el-button
            >
          </div>
        </div>
        <div class="box">
          <div class="title">摄像头视频截图</div>
          <img :src="form.cameraCaptureUrl" alt="" v-if="cameraImgUrlIsGood === 2" />
          <div v-if="cameraImgUrlIsGood === 1" class="noImg">
            <div>当前无截图</div>
          </div>
          <div>
            <el-button @click="linklive(1)" type="text" size="small" class="url" v-if="form.cameraLiveUrl"
              >查看摄像头视频</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upgrade } from 'src/api/legacy'

export default {
  data() {
    return {
      id: '',
      productId: '',
      form: '',
      screenImgUrlIsGood: 0,
      cameraImgUrlIsGood: 0,
    }
  },
  mounted() {
    this.id = this.$route.query.boxId
    this.productId = this.$route.query.productId
    this.overviewInit()
  },
  methods: {
    getADBoxInfo() {
      upgrade
        .getADBoxInfo({
          boxId: this.id,
          productId: this.productId
        })
        .then((res) => {
          if (res && res.status === 0) {
            this.form = res.data
            console.log(this.form)
          }
          if (this.form) {
            this.handleImgUrl(this.form.screenCaptureUrl, 0)
            this.handleImgUrl(this.form.cameraCaptureUrl, 1)
          }
        })
        .catch((error) => {
          this.screenImgUrlIsGood = 1
          this.cameraImgUrlIsGood = 1
        })
    },
    handleImgUrl(url, flag) {
      var img = new Image()
      img.src = url
      img.onload = () => {
        if (flag) {
          this.cameraImgUrlIsGood = 2
        } else {
          this.screenImgUrlIsGood = 2
        }
      }
      img.onerror = () => {
        if (flag) {
          this.cameraImgUrlIsGood = 1
        } else {
          this.screenImgUrlIsGood = 1
        }
      }
      img = null
    },
    overviewInit() {
      //页面初始化
      this.getADBoxInfo()
    },
    linklive(flag) {
      sessionStorage.setItem('ADBoxInfoObj', JSON.stringify(this.form))
      this.$router.push({
        name: 'adBox-deviceManage-live',
        query: {
          flag,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.containerX .contentX {
  padding-left: 0;
  padding-right: 0;
}

.titletop {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 20px;
}

.titletop span {
  font-size: 14px;
  color: #5c76cc;
}

.titletop span i {
  color: #333;
}

.content {
  padding: 0 20px 20px;
}
.content .detail {
  padding: 10px 0;
  display: flex;
  width: 100%;
  font-size: 14px;
  /*justify-content: space-between;*/
  /*border-bottom: 1px solid #D1D1D1;*/
}
.content .detail .box {
  display: flex;
  align-items: center;
  width: 20%;
}
.contentUrl {
  font-size: 22px;
  border-top: 5px solid #d1d1d1;
}
.contentUrl .detail {
  width: 100%;
  display: flex;
  /*justify-content: center;*/
}
.contentUrl .detail .box {
  padding: 15px 0 0 15px;
  /*display: flex;*/
  /*align-items: center;*/

  width: 50%;
}
.contentUrl .detail .box .title {
  margin-bottom: 15px;
}
.contentUrl .detail .box img {
  width: 70%;
}
.contentUrl .detail .box .noImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  border: 1px #ccc dashed;
  height: 320px;
  font-size: 16px;
  color: #ccc;
}

.contentUrl .detail .box .url {
  font-size: 20px;
  /*padding-left: 25%;*/
}
</style>